<!--
Composant des listes de villes
-->
<template>
	<div class="listCitiesContainer">
		<!-- Input search | trigger  -->
		<input :id="inputId"
		       :class="inputClass" class="dropdown-input"
		       required
	           @focus="initListContainer()"
	           @blur="hideListContainer()"
	           v-model="searchFilter"
	           :placeholder="placeholder"
	    />
	    <!-- Cities List  -->
	    <ul v-show="showListContainer" class="dropdown-content select-dropdown">
		    <li class="disabled">
			    <span>{{ firstItemText }}</span>
		    </li>
	        <li
	            class=""
	            @mousedown="selectOption(option)"
	            v-for="(option, index) in options"
	            :key="index">
		        <span>{{ option.Ville }}</span>
	        </li>
	    </ul>
	</div>
</template>

<script>
/**
 * TODO: We have to rethink the whole concept of the city and its updating. The data model was changed a few
 * times after creating this component, and this component was only incremented in complexity without any
 * refactoring.
 */
	import { getApiService } from "@/services/getApi.service";

	export default {
		name: "ListCities",
		props: {
			inputId: String,
			inputClass: String,
			countryModel: String,
			regionModel: String,
		    cityModel: String,
			placeholder: String,
		    firstItemText: String,
      lieu: String,
		},
		data() {
			return {
				options: [],
				searchFilter: this.cityModel,
				showListContainer: false,
				codeVille: null,
        emitChange: true,
				cameFromSearchFilter: true
			};
		},
		methods: {
			checkRequirements(city) {
				return (
					(this.countryModel && this.countryModel.length >= 2) &&
					(city && city.length >= 3 && city.trim() !== '')
				);
			},
			async getUpdatedCitiesList(city) {
				// TODO : temp : onChangeCountryBirth() set a space to default region
				const region = (this.regionModel && this.regionModel !== ' ') ? this.regionModel : '';
				const dataCitiesList = await getApiService.getLoCode(this.countryModel, region, city);
				this.options = dataCitiesList.data;
				if (this.options.length === 0) {
					this.hideListContainer();
				}
			},
			initListContainer() {
				if (this.cityModel) {
					// show|update / hide list
					this.validateList(this.cityModel);
				}
			},
			hideListContainer() {
				this.showListContainer = false;
				this.options = [];
			},
			selectOption(option) {
				this.searchFilter = option.Ville;
				this.codeVille = option.LoCode;
				// update model at parent component
			    // TODO: stop propagation? watch searchFilter() will $emit another update
				const selectedCity = {'loCode': this.codeVille, 'cityName': this.searchFilter };
				this.$emit('selected', selectedCity, this.lieu);
				// clear and hide list
				this.hideListContainer();
        this.emitChange = false;
        this.searchFilter = this.cityModel;
        this.codeVille = '';
			},
			validateList(city) {
				if (this.checkRequirements(city)) {
					// update list
					this.getUpdatedCitiesList(city);
					this.showListContainer = true;
				} else {
					// clear and hide list
					this.hideListContainer();
				}
			}
		},
		watch: {
			searchFilter() {
        if (this.emitChange) {
          // update model at parent component
          const selectedCity = {'loCode': this.codeVille, 'cityName': this.searchFilter };
          this.$emit('selected', selectedCity, this.lieu);
          // show|update / hide list
          this.validateList(this.searchFilter);
        }
				/**
				 * TODO: We have to rethink the whole concept of the city and its updating. The data model was changed a few
				 * times after creating this component, and this component was only incremented in complexity without any
				 * refactoring.
				 * emitChange was created to prevent propagation. However, cityModel should override that (this.emitChange =
				 * false;) only if it has NOT been activated from this method. This is the reason why
				 * cameFromSearchFilter was created, to fix a bug.
				 */
        this.emitChange = true;
        this.cameFromSearchFilter = true;
			},
      cityModel() {
        this.searchFilter = this.cityModel;
        if (!this.cameFromSearchFilter) {
	        this.emitChange = false;
	      }
	      this.cameFromSearchFilter = false;
      }
		}
	};
</script>
<style lang="scss" scoped>
	.listCitiesContainer {
		position: relative;

		ul {
			display: block;
			width: 100%;
			left: 0;
			top: 40px;
			height: 400px;
			transform-origin: 0 100%;
			opacity: 1;
			transform: scaleX(1) scaleY(1);
		}
	}
</style>