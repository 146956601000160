<!--
Custom Component for Tooltips
-->
<template>
	<div class="tooltip-container">
		<!-- tooltip trigger -->
		<button ref="tooltipTrigger"
		        class="ml-5 isni-tooltip--trigger"
		        v-on:focusin="onFocus()"
		        v-on:mouseover="setActiveShort()"
		        v-on:mouseout="unsetActiveShort()"
		        v-on:click="setActiveLong($event)"
		        v-on:keyup.space="setActiveLong($event)"
				tabindex="-1"
		>
			<i class="material-icons tiny light-blue-text" aria-hidden="true">help</i>
		</button>

		<!-- tooltip container and content -->
		<div v-show="showContent"
		     ref="tooltipPageContainer"
		     class="isni-tooltip--page-container"
		     :class="{ 'is-active': isActiveLong || isActiveShort }"
		     :style="tooltipStyle.pageContainer"
		>
			<div class="isni-tooltip--content-container" :style="tooltipStyle.contentContainer">
				<span ref="tooltipContent"
				      class="isni-tooltip--content"
				      :style="tooltipStyle.content"
				      v-html="content"
				>
					<slot></slot>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "IsniTooltip",
	props: {
		content: {
			type: String,
			required: true
		}
	},
	data: function () {
		return {
			isActiveShort: false,
			isActiveLong: false,
			longTimeDelay: 3000,
			nextActiveShort: false,
			showContent: true,
			tooltipStyle: {
				content: {},
				contentContainer: {},
				pageContainer: {}
			}
		};
	},
	methods: {
		onFocus() {
			this.showContent = true;
		},
		/**
		 * showContent is used to hide the content ONLY AFTER the setInitialPositions and for all iterations after that.
		 * Is based on isActiveLong and isActiveShort.
		 * It is needed to used separated of isActiveLong and isActiveShort for the reasons:
		 *  1. the component tooltipPageContainer must to be displayed (but opacity 0) to the correct resize and
		 *  position, immediately after the page is ready.
		 *  2. the component tooltipPageContainer must be hide, not opacity = 0, because of the possible links inside the
		 *  content. If it is not hide then the link can be accessed by keyboard even if it has opacity = 0;
		 *  3. we could use only tooltipPageContainer = hide, and not isActiveLong and isActiveShort. However, the
		 *  complexity and risks to manage the hide of a component based on the possible hover of others (hover on
		 *  trigger, for example) does not justify to not use another indicator. In the end, hover on trigger is no more
		 *  used, but the complexity keeps more simple.
		 */
		setShowContent() {
			this.showContent = this.isActiveLong || this.isActiveShort;
			this.nextActiveShort = false;
		},
		setActiveLong(event) {
			event.preventDefault();
			this.isActiveLong = !this.isActiveLong;
			this.setShowContent();

			if (this.isActiveLong) {
				setTimeout(() => {
					this.isActiveLong = false;
					this.setShowContent();
				}, this.longTimeDelay);
			}
		},
		setActiveShort() {
			// setting only showContent = true will resize in the next cycle, then nextActiveShort will
			// activate the content also in the next cycle
			this.showContent = true;
			this.nextActiveShort = true
		},
		unsetActiveShort() {
			this.isActiveShort = false;
			this.setShowContent();
		},
		/**
		 * When page is full loaded, it sets:
		 *  1. position top and left of the pageContainer
		 *  2. Size and position of the content, based on
		 *    a. message size
		 *    b. trigger position
		 *    c. message size VS trigger positions too close of the page border
		 */
		setInitialPositions() {
			const windowWidth = window.innerWidth;
			const windowBorder = Math.floor(windowWidth * 0.05);
			const trigger = this.$refs['tooltipTrigger'].getBoundingClientRect();
			const triggerWidth = trigger.width;
			const triggerLeft = trigger.left; // = absolute position left = 0
			const triggerRight = windowWidth - triggerLeft - triggerWidth;

			// setup pageContainer
			// set left
			this.tooltipStyle.pageContainer.left = '-' + (triggerLeft - windowBorder) + 'px';

			// setup Content Position
			const tcontent = this.$refs['tooltipContent'].getBoundingClientRect();
			const tcontentWidth = tcontent.width;
			const tcontentHalfWidth = tcontentWidth / 2;
			// check limit left
			const hasLimitLeft = tcontentHalfWidth + windowBorder > triggerLeft;
			// check limit right
			const hasLimitRight = tcontentHalfWidth + windowBorder > triggerRight;

			// set position
			if (!hasLimitLeft) {
				// check limit right to not set disrupt the position
				if (!hasLimitRight) {
					// centralize content with the trigger
					this.tooltipStyle.content['margin-left'] = (triggerLeft - windowBorder - tcontentHalfWidth + (triggerWidth / 2))
						+ 'px';
				} else {
					// align to the right
					this.tooltipStyle.contentContainer['text-align'] = 'end';
				}
			}
		}
	},
	mounted: function () {
		this.setInitialPositions();
		this.setShowContent();
	},
	updated: function () {
		if (!this.isActiveShort && !this.isActiveLong && this.showContent) {
			this.setInitialPositions();
			if (this.nextActiveShort) {
				this.isActiveShort = true;
			}
			this.setShowContent();
		}
	}
};
</script>

<style>
	.tooltip-container {
		position: relative;
		display: inline-block;
	}
	/*tooltip trigger*/
	.isni-tooltip--trigger {
		padding: 0;
		background: transparent;
		border: transparent;
		color: #03a9f4;
	}
	.isni-tooltip--trigger .light-blue-text {
		color: inherit !important;
	}
	.isni-tooltip--trigger:hover {
		cursor: pointer;
	}
	.isni-tooltip--trigger:focus {
		outline: none;
		background-color: transparent;
	}
	.isni-tooltip--trigger:hover,
	.isni-tooltip--trigger:focus {
		color: rgb(0 129 244);
	}
	/*tooltip container and content*/
	.isni-tooltip--page-container {
		position: absolute;
		bottom: calc(100% + 5px);
		width: 90vw;
		z-index: 2000;
		opacity: 0;
		transition: all .08s ease-in-out .15s;
		pointer-events: none;
		white-space: nowrap;
	}
	.isni-tooltip--content {
		display: inline-block;
		padding: 6px 8px;
		border-radius: 2px;
		color: #fff;
		background-color: #323232;
		line-height: 120%;
		white-space: normal;
		font-size: 1rem;
		font-weight: 400;
		text-align: center;
	}
	.isni-tooltip--page-container.is-active {
		opacity: 1;
		pointer-events: auto;
	}
</style>
