<!--
Composant StepProgress
Affichage des etapes sur le formulaires

une props => lenght pour le nombre d'etape
son style est scoped (utilise que pour ce composant)

-->
<template>
  <div class="row wrapper-step-progress">
    <div class="col s12">
      <h2 class="h5-title center-align">{{ titleStep }}</h2>
      <br />
    </div>
    <div class="container">
      <ul class="progressbar">
        <li :class="{ active: propsStep >= 1 }">25%</li>
        <li :class="{ active: propsStep >= 2 }">50%</li>
        <li :class="{ active: propsStep >= 3 }">75%</li>
        <li :class="{ active: propsStep >= 4 }">100%</li>
        <!-- Todo: ajouter un currentActive pour l'epate -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepProgress",
  props: {
    dataText: {
      type: Object,
      required: true
    },
    titleStep: {
      type: String,
      required: true
    },
    propsStep: {
      type: Number,
      required: true
    }
  }
};
</script>

<style scoped>
/* (html comment removed:  Add "scoped" attribute to limit CSS to this component only ) */
.h5-title{
  font-size: 1.64rem;
  line-height: 110%;
  margin: 1.0933333333rem 0 .656rem 0;
}
.progressbar {
  counter-reset: step;
}
.progressbar li {
  float: left;
  /* width: 14.28%; */
  width: 25%;
  position: relative;
  text-align: center;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 50px;
  height: 50px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 47px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}
.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background: #979797;
  top: 25px;
  left: -50%;
  z-index: -1;
}
.progressbar li.active:before {
  border-color: #ffa000;
  background: #ffa000;
  color: white;
}
.progressbar li.active:after {
  background: #ffa000;
}
.progressbar li.active - li:after {
  background: #3aac5d;
}
.progressbar li.active - li:before {
  border-color: #3aac5d;
  background: #3aac5d;
  color: white;
}
.progressbar li:first-child:after {
  content: none;
}
</style>
