export const validateDateService = {
	/**
	 *
	 * @param _year
	 * @param _month
	 * @param _day
	 * @param relationWithToday
	 * @param {String} typeDate : 'DateNaissance', 'DateDeces', 'DateDebut', 'DateFin'
	 * @returns {{date: *, error: string}|{fullDate: null, error: {errorLabelKeys: [], hasError: boolean}}}
	 * @description
	 */
	checkIndividualDate(dateObj, relationWithToday, typeDate) {
		const activateError = function () {
			_hasError = true;
		};
		const validationResult = function () {
			return {
				error: {
					hasError: _hasError,
					errorLabelKeys: _errorKeys
				},
				fullDate: _fullDate
			}
		};
		const _day = dateObj.jour;
		const _month = dateObj.mois;
		const _year = dateObj.annee;
		const _yearLimit = new Date().getFullYear();
		const _errorKeys = [];
		let _hasError = false;
		let _fullDate = null;

		// message: 'Veuillez sélectionner une année de X.'
		if (!_year && ((_month || _day) || typeDate === 'DateDebut')) {
			activateError();
			_errorKeys.push('e2PIdpublique' + typeDate + 'AnneeVideErreur');
		}
		// message: 'Veuillez sélectionner une année et un mois de X.'
		if (!_month && _day) {
			activateError();
			_errorKeys.push('e2PIdpublique' + typeDate + 'MoisVideErreur');
		}
		if (_year) {
			// message: 'Veuillez saisir une année de X valide'
			if (_year.length > 4 || _year < 0 || _year > _yearLimit) {
				activateError();
				_errorKeys.push('e2PIdpublique' + typeDate + 'AnneeInvalideErreur');
			}
			if (_month && _day) {
				const _adjustedMonth = _month.split('_')[1] - 1;
				const _tempDate = new Date(_year, _adjustedMonth, _day, 0, 0, 0, 0);
				// validate if the date is a valid date (not 31/february, for example)
				const _isValidDay = !!(typeof _tempDate.getMonth == 'function' && _tempDate.getDate() == _day) || false;
				// message: 'Veuillez saisir une date de X valide.'
				if (!_isValidDay) {
					activateError();
					_errorKeys.push('e2PIdpublique' + typeDate + 'DateInvalideErreur');
				}
				// validate if the date is not bigger than or equal today
				const _today = new Date();
				_today.setHours(0, 0, 0, 0);
				// message: 'La date de X doit précéder la date d’aujourd’hui.'
				if (
					(relationWithToday === '>=' && _tempDate >= _today) ||
					(relationWithToday === '>' && _tempDate > _today)
				) {
					activateError();
					_errorKeys.push('e2PIdpublique' + typeDate + 'PlusGrandQAujourdhuiErreur');
				}
				_fullDate = _tempDate;
			}
		}
		return validationResult();
	},
	/**
	 *
	 * @param firstDateObj
	 * @param lastDateObj
	 * @param typeDate
	 * @returns { boolean }
	 * @description
	 * lastDate cannot be bigger than firstDate
	 * all cases:
        code  | first date      | last date    | Status
			  ----  | YY - MM - DD    | YY - MM - DD    | ------
      	c1		| 20 - ** - **    | 20 - ** - **    | ok
	      c2    | 20 - ** - **    | 19 - ** - **    | ERROR E1
		    c3  	|	   - ** - **    | 19 - ** - **    | ok
		    c4  	|		 - ** - **    | 20 - ** - **    | ok
	      c5		| 20 -    - **    | 20 - 03 - **    | ok
		    c6  	| 20 -    - **    | 20 - 03 - **    | ok
		    c7  	| 20 - 03 - **    | 20 -    - **    | ok
	      c8		| 20 - 03 - **    | 20 - 02 - **    | ERROR E2
		    c9   	| 20 - 03 - **    | 20 - 03 - **    | ok
	      c10   | 20 - 03 - **    | 20 - 04 - **    | ok
			  c11   | 20 - 03 -       | 20 - 03 - 03    | ok
			  c12   | 20 - 03 - 03    | 20 - 03 -       | ok
	      c13   | 20 - 03 - 03    | 20 - 03 - 02    | ERROR E3
			  c14   | 20 - 03 - 03    | 20 - 03 - 03    | ok
			  c15   | 20 - 03 - 03    | 20 - 03 - 04    | ok
	 */
	compareDates(firstDateObj, lastDateObj, typeDate) {
		const comparisonResult = function (hasError, errorKey) {
			return {
				error: {
					hasError: hasError,
					errorLabelKeys: [errorKey]
				}
			};
		};

		if (firstDateObj && lastDateObj) {
			const firstYear = firstDateObj.annee;
			const firstMonth = firstDateObj.mois;
			const firstDay = firstDateObj.jour;
			const lastYear = lastDateObj.annee;
			const lastMonth = lastDateObj.mois;
			const lastDay = lastDateObj.jour;

			if (lastYear) {
				// check years
				if (
					firstYear &&
					firstYear > lastYear
				) {
					// E1
					return comparisonResult(true, 'e2PIdpublique' + typeDate + 'PlusPetitQPrecedentErreur');
				}
				// check months
				if (
					lastYear === firstYear &&
					firstMonth &&
					lastMonth &&
					firstMonth > lastMonth
				) {
					// E2
					return comparisonResult(true, 'e2PIdpublique' + typeDate + 'PlusPetitQPrecedentErreur');
				}
				// check full date
				if (
					lastYear === firstYear &&
					firstMonth === lastMonth &&
					firstDay &&
					lastDay &&
					firstDateObj.fullDate > lastDateObj.fullDate
				) {
					// E3
					return comparisonResult(true, 'e2PIdpublique' + typeDate + 'PlusPetitQPrecedentErreur');
				}
			}
		}
		return comparisonResult(false, null);
	}
};
