<!--
Composant des icones utilises
https://materializecss.com/tooltips.html

iconText est le parametre a passer pour l'icone souhaite en props
-->
<template>
  <i class="material-icons tiny light-blue-text">{{ iconText }}</i>
</template>
<script>
export default {
  name: "Icon",
  props: {
    iconText: String
  }
};
</script>
