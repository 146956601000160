<template>
  <div
    class="isni-wrapper-error red lighten-5 z-depth-2 pg-10"
    v-if="errors.length"
  >
    <div class="col s12 valign-wrapper isni-error__message">
      <i class="small material-icons red-text text-accent">warning</i>

      <strong
        class="isni-error__advertisement red-text text-accent-4 fw-900 fs-26"
        v-if="errors.length <= 1"
        >{{ dataText.eCTitreErreur }}</strong
      >
      <strong
        class="isni-error__advertisement red-text text-accent-4 fw-900 fs-26"
        v-else
        >{{ dataText.eCTitreErreurs }}</strong
      >
    </div>
    <ul>
      <li
        class="isni-error__array red-text"
        v-for="(error, index) in errors"
        :key="index"
      >
        {{ index + 1 }} : {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ListeErrors",
  props: {
    errors: { type: Array, required: true },
    dataText: {
      type: Object,
      required: true
    }
  }
};
</script>
<style lang="scss" scoped>
.isni-wrapper-error {
  .isni-error__array {
    font-size: 1rem;
  }
}
</style>
