import axios from 'axios'
import {Constants} from "../constants";

export const postApiService = {
  async postForm(form) {

    // CHANGE DEPLOY ACP / DEV
    // Pour déployer dans les serveurs d'acceptation, utiliser : Constants.ENV.acp
    // Pour déployer dans les serveurs de développement, utiliser : Constants.ENV.dev
    const domain = Constants.ENV.prod.baseUrl;

    const urlApi = domain + "web/formulaireWeb/soumettre";

    return await axios
      .post(urlApi, form)
      .then(res => res)
      .catch(error => error);
  },
  async sendEmailValidation(dataOrigin, typeDemande) {
    // CHANGE DEPLOY ACP / DEV
    // Pour déployer dans les serveurs d'acceptation, utiliser : Constants.ENV.acp
    // Pour déployer dans les serveurs de développement, utiliser : Constants.ENV.dev
    const domain = Constants.ENV.prod.baseUrl;
    const urlApi = domain + "web/formulaireWeb/validerCourriel";

    // prepare data to send
    const data = {
      "email": dataOrigin.email,
      "typeDemande": typeDemande,
      "langueFormulaire": dataOrigin.langueFormulaire
    };

    return await axios
      .post(urlApi, data)
      .then(res => res)
      .catch(error => error);
  }
}