<template>
  <div
    class="col l12 s12 mt-50 isni-wrapper-condition "
    id="admissibilite"
    v-if="step == 1"
  >
    <hr style="" class="bdzdetph light-blue darken-1" />
    <p>
      {{ dataText.e1CConsentementConditionsTexte }}
    </p>
    <p class="text-lighten-4" v-html="dataText.e1CAdmissibiliteConditionsTexte"></p>
  </div>
</template>
<script>
export default {
  name: "Conditions",
  props: {
    step: {
      type: Number,
      required: true
    },
    dataText: {
      type: Object,
      required: true
    }
  }
};
</script>
<style lang="scss" scoped>
.isni-wrapper-condition {
  font-size: 0.8rem;
  .bdzdetph {
    width: 50%;
    height: 3px;
    border: none;
  }
}
</style>
